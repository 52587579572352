define("moments/models/campaign", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    campaignLogo: belongsTo('campaign-logo'),
    contentCollections: hasMany('content-collection'),
    cssUrl: attr('string'),
    iterationId: attr('string'),
    jsUrl: attr('string'),
    maxDepth: attr('number'),
    outcomeGroup: belongsTo('outcome-group'),
    templateVersion: attr('string'),
    transition: attr('string'),
    nodes: hasMany('node')
  });

  _exports.default = _default;
});